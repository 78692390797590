import { CONFIG_TYPE } from "../constants/configTypes";
import { IAM_STATUSES } from "../constants/iamStatuses";
import { User } from "./User";

export interface ClientResponse {
  id: number;
  accreditationId: number;
  entityId: number;
  name: string;
  slug: string;
  isIntroducer: boolean | null;
  bdmId: number | null;
  dealRecipientClientId: number | null;
  clientConfig: ClientData | null;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  configType: CONFIG_TYPE;
  bdm?: User | null;
  agentCount?: number;
}

export const clientResponseDefaultValue: ClientResponse = {
  id: 0,
  accreditationId: 0,
  entityId: 0,
  name: "",
  slug: "",
  isIntroducer: true,
  bdmId: null,
  dealRecipientClientId: null,
  clientConfig: null,
  status: IAM_STATUSES.ACTIVE,
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
  configType: CONFIG_TYPE.EXTERNAL_BROKERAGE,
};

export interface AdvanceOrArrears {
  default: string;
  options: string[];
}

export interface ContractDocument {
  default: string;
  options: string[];
}

export interface IncludeFees {
  default: boolean;
  options: boolean[];
}

export interface RepaymentTermOption {
  default: string;
  options: string[];
}

export interface ClientData {
  type: string;
  advanceOrArrears: AdvanceOrArrears;
  contractDocument: ContractDocument;
  includeFees: IncludeFees;
  repaymentTermOption: RepaymentTermOption;
}

export enum CONFIG_TYPE {
  INTERNAL_BROKERAGE = "INTERNAL_BROKERAGE",
  EXTERNAL_BROKERAGE = "EXTERNAL_BROKERAGE",
}

export const CONFIG_TYPES = [
  {
    value: CONFIG_TYPE.INTERNAL_BROKERAGE,
    label: "Internal Brokerage",
  },
  {
    value: CONFIG_TYPE.EXTERNAL_BROKERAGE,
    label: "External Brokerage",
  },
];
